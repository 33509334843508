
import type { FlickingOptions, Flicking } from '@egjs/vue-flicking'
import {
  Component, Inject, Prop, Vue,
} from 'nuxt-property-decorator'
import IStageContent from '../../shared/fsxa/interfaces/stage/IStageContent'
import CarouselElement from './CarouselElement.vue'
import BaseIconButton from '../base/BaseIconButton.vue'

@Component({
  name: 'Carousel',
  components: {
    CarouselControls: () => import('./CarouselControls.vue'),
    CarouselElement,
    BaseIconButton,
    Flicking: async () => (await import('@egjs/vue-flicking')).Flicking,
  },
})
export default class Carousel extends Vue {
  @Prop({ required: true }) slides! : IStageContent[]

  @Prop({ required: true }) variant! : 'stage-large' | 'stage-medium' | 'carousel'

  @Inject({ from: 'carouselUsedInContentProjection', default: false }) usedInContentProjection! : boolean

  $refs! : {
    carousel ?: Flicking
  }

  private showControls! : boolean

  private currentIndex : number = 0

  private flickingOptions : FlickingOptions = {
    horizontal: true,
    circularFallback: 'linear',
    circular: true,
    panelsPerView: 1,
    preventClickOnDrag: false,
    align: 'prev',
    inputType: ['pointer'],
  } as FlickingOptions

  private wrapperSpacings : Record<string, string> = {
    'stage-large': 'p-6 sm:px-8 sm:py-6 md:px-12 md:py-10 xl:p-12',
    'stage-medium': 'p-6 sm:px-8 sm:py-6 md:px-12 md:py-10 xl:p-12',
    carousel: 'px-4 py-6 sm:p-6',
  }

  created () {
    this.showControls = this.slides.length > 1
  }

  private async move (index : number) : Promise<void> {
    try {
      await this.$refs.carousel?.moveTo(index)
    } catch (e) { /* ignore errors */ }
  }

  private updateIndex (index : number) : void {
    this.currentIndex = index
  }
}
